import React, { useRef, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid';

const LineChart = ({
    dataArray,
    headers,
    parentRef,
    xAxes,
    xLabel,
    yLabel,
    yMin,
    yMax,
    xMin,
    xMax,
    showXGridLines,
    showYGridLines,
    legendPosition,
    lineThickness,
    lineType,
    pointType,
    pointSize,
    opacity,
    colorScheme
}) => {
    const ref = useRef();
    const data = dataArray;
    const longestLabel = headers.reduce((maxLength, current) => {
        return current.length > maxLength ? current.length : maxLength;
    }, 0);

    // Mapping functions
    const getPointSize = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([1, 5]);
        return scale(value);
    };

    const getLineThickness = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([1, 3]);
        return scale(value);
    };

    const getOpacity = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([0.1, 1]);
        return scale(value);
    };

    useLayoutEffect(() => {
        d3.select(ref.current).selectAll('*').remove();
        const xVals = data.map(d => d[xAxes]);
        // Filter out undefined and NaN values and values outside of the range
        const yVals = data[0].map((_, i) => {
            if (i !== xAxes) {
                return data.map(row => row[i]);
            }
            return null;
        }).filter(yVal => yVal !== null);
        // Filter out undefined and NaN values and values outside of the range
        // delete empty columns

        // Check if (yMin, yMax) range is still applicable
        // const newYMin = d3.min(yVals.map(row => d3.min(row)));
        // const newYMax = d3.max(yVals.map(row => d3.max(row)));
        // if (yMin > newYMin) yMin = newYMin;
        // if (yMax < newYMax) yMax = newYMax;

        const margin = { top: 30, right: 30, bottom: 50, left: 20 };

        let width = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200) - margin.left - margin.right;
        let height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;

        if (legendPosition === 'top') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.top += 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        } else if (legendPosition === 'right') {
            margin.right += longestLabel * 10;
            width = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200) - margin.left - margin.right;
        } else if (legendPosition === 'bottom') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.bottom += 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        } else if (legendPosition === 'top-right') {
            margin.top += headers.length * 20;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        }

        const uniqueId = uuidv4();

        const svg = d3.select(ref.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('id', 'linechart-svg')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .attr('id', 'linechart-svg-group' + uniqueId);

        console.log(document.getElementById('linechart-svg-group'));

        // const newXMin = d3.min(xVals);
        // const newXMax = d3.max(xVals);
        // if (xMin > newXMin) xMin = newXMin;
        // if (xMax < newXMax) xMax = newXMax;

        const xScale = d3.scaleLinear().domain([xMin - 0.1, xMax + 0.1]).range([0, width]);
        const yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);
        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);
        const xAxisGrid = d3.axisBottom(xScale).tickSize(-height).tickFormat('');
        const yAxisGrid = d3.axisLeft(yScale).tickSize(-width).tickFormat('');

        if (showXGridLines) {
            svg.append('g')
                .attr('class', 'x axis-grid')
                .attr('transform', 'translate(0,' + height + ')')
                .call(xAxisGrid);
        }
        if (showYGridLines) {
            svg.append('g')
                .attr('class', 'y axis-grid')
                .call(yAxisGrid);
        }

        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);
        svg.append('g')
            .attr('class', 'y axis')
            .attr('id', 'y-axis' + uniqueId)
            .call(yAxis);

        const colorScale = (index) => colorScheme[index % colorScheme.length];

        const lineLegend = svg.selectAll('.lineLegend').data(headers)
            .enter().append('g')
            .attr('class', 'lineLegend')
            .attr('transform', (d, i) => `translate(${width - 40},${i * 20})`);

        let legendLabelMaxLen = 0;
        lineLegend.append('text')
            .text(d => d)
            .attr('transform', 'translate(15,9)')
            .each(function (d) {
                const textNode = d3.select(this).node();

                if (textNode && typeof textNode.getComputedTextLength === 'function') {
                    const len = textNode.getComputedTextLength();
                    legendLabelMaxLen = Math.max(legendLabelMaxLen, len);
                } else {
                    console.warn('getComputedTextLength is not available on this element');
                }
            });

        lineLegend.append('rect')
            .attr('fill', (d, i) => colorScale(i))
            .attr('width', 10).attr('height', 10);

        // Adjust legend position based on legendPosition prop
        if (legendPosition === 'top') {
            const legendWidth = Math.min(width, headers.length * (legendLabelMaxLen + 20));
            const startX = (width - legendWidth) / 2;
            lineLegend.attr('transform', function (d, i) {
                const x = startX + (i % Math.floor(width / (legendLabelMaxLen + 20))) * (legendLabelMaxLen + 20);
                const y = Math.floor(i / Math.floor(width / (legendLabelMaxLen + 20))) * 20 - margin.top + 10;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'bottom') {
            const legendWidth = Math.min(width, headers.length * (legendLabelMaxLen + 20));
            const startX = (width - legendWidth) / 2;
            const itemsPerRow = Math.floor(width / (legendLabelMaxLen + 20));
            lineLegend.attr('transform', function (d, i) {
                const x = startX + (i % itemsPerRow) * (legendLabelMaxLen + 20);
                const y = height + 40 + Math.floor(i / itemsPerRow) * 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'right') {
            const legendHeight = Math.min(height, headers.length * 20);
            const startY = (height - legendHeight) / 2;
            lineLegend.attr('transform', function (d, i) {
                const x = width + 5;
                const y = startY + (i % Math.floor(height / 20)) * 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'top-right') {
            lineLegend.attr('transform', function (d, i) {
                return 'translate(' + (width - legendLabelMaxLen) + ',' + (-30 - (i * 20)) + ')';
            });
        }

        const linePoints = [];
        yVals.forEach((col, colIdx) => {
            const colPoints = [];
            col.forEach((entry, entryIdx) => {
                if (xVals[entryIdx] !== undefined && !isNaN(xVals[entryIdx]) && entry !== undefined && !isNaN(entry)) {
                    colPoints.push({ x: xVals[entryIdx], y: entry });
                } else {
                    colPoints.push({ x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY });
                }
            });
            linePoints.push(colPoints);
        });

        const lineStyles = {
            solid: '',
            dashed: '6,3',
            dotted: '2,2'
        };

        // Apply mapped values
        const thickness = getLineThickness(lineThickness);
        const opac = getOpacity(opacity);
        const size = getPointSize(pointSize);

        // Draw the lines
        linePoints.forEach((col, colIdx) => {
            const usedCol = linePoints[colIdx].filter(
                point => point.x !== Number.POSITIVE_INFINITY &&
                point.x >= xMin &&
                point.x <= xMax &&
                point.y !== Number.POSITIVE_INFINITY &&
                point.y >= yMin &&
                point.y <= yMax);
            console.log(usedCol);
            svg.append('path')
                .datum(usedCol)
                .attr('fill', 'none')
                .attr('stroke', colorScale(colIdx))
                .attr('stroke-width', thickness)
                .attr('stroke-dasharray', lineStyles[lineType])
                .attr('opacity', opac)
                .attr('d', d3.line()
                    .x(d => xScale(d.x))
                    .y(d => yScale(d.y))
                );
        });

        // Draw the points with adjusted sizes and shapes
        linePoints.forEach((col, colIdx) => {
            col.forEach(point => {
                if (
                    (point.x === Number.POSITIVE_INFINITY || point.y === Number.POSITIVE_INFINITY) ||
                    (point.x < xMin || point.x > xMax) ||
                    (point.y < yMin || point.y > yMax)) {
                    console.log('pointSkip', point);
                } else {
                    console.log('point', point);
                    if (pointType === 'circle') {
                        svg.append('circle')
                            .style('stroke', colorScale(colIdx))
                            .style('fill', colorScale(colIdx))
                            .attr('r', size)
                            .attr('cx', xScale(point.x))
                            .attr('cy', yScale(point.y))
                            .style('opacity', opac);
                    } else if (pointType === 'rect') {
                        svg.append('rect')
                            .style('stroke', colorScale(colIdx))
                            .style('fill', colorScale(colIdx))
                            .attr('width', size * 2)
                            .attr('height', size * 2)
                            .attr('x', xScale(point.x) - size)
                            .attr('y', yScale(point.y) - size)
                            .style('opacity', opac);
                    } else if (pointType === 'triangle') {
                        svg.append('path')
                            .style('stroke', colorScale(colIdx))
                            .style('fill', colorScale(colIdx))
                            .attr('d', d3.symbol().type(d3.symbolTriangle).size(Math.pow(size, 2) * 5))
                            .attr('transform', `translate(${xScale(point.x)}, ${yScale(point.y)})`)
                            .style('opacity', opac);
                    }
                }
            });
        });

        // Adds x-axis label and y-axis label
        svg.append('text')
            .attr('class', 'x label')
            .attr('text-anchor', 'middle')
            .attr('x', width / 2)
            .attr('y', height + 30)
            .style('font-size', '0.75em')
            .text(xLabel);

        // Get bounding box of the <g> element
        const outerGroup = document.getElementById('linechart-svg-group' + uniqueId);
        const yAx = document.getElementById('y-axis' + uniqueId);

        const yAxWidth = yAx.getBoundingClientRect().width;

        // Calculate translation to center the <g> element
        const translateX = (0 + yAxWidth + margin.left);
        const translateY = (margin.top);

        // Apply the transformation
        outerGroup.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${0.9})`);

        svg.append('text')
            .attr('class', 'y label')
            .attr('text-anchor', 'middle')
            .style('font-size', '0.75em')
            .attr('x', -height / 2)
            .attr('y', -translateX + 0.2 * margin.left)
            .attr('dy', '.75em')
            .attr('transform', 'rotate(-90)')
            .attr('id', 'y-axis-label' + uniqueId)
            .text(yLabel);
    }, [
        data,
        parentRef.current ? d3.select(parentRef.current.parentElement).node().offsetWidth : 200,
        JSON.stringify(data),
        JSON.stringify(headers),
        xAxes,
        xLabel,
        yLabel,
        yMin,
        yMax,
        xMin,
        xMax,
        showXGridLines,
        showYGridLines,
        legendPosition,
        lineThickness,
        lineType,
        pointType,
        pointSize,
        opacity,
        colorScheme,
        longestLabel
    ]);

    return (
        <svg ref={ref} data-testid="linechart"></svg>
    );
};

export default LineChart;
