import React, { useRef, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid';

const ScatterPlot = ({
    dataArray,
    headers,
    parentRef,
    xAxes,
    xLabel,
    yLabel,
    yMin,
    yMax,
    xMin,
    xMax,
    showXGridLines,
    showYGridLines,
    legendPosition,
    pointSize,
    pointType,
    opacity,
    colorScheme

}) => {
    const ref = useRef();
    const data = dataArray;
    const longestLabel = headers.reduce((maxLength, current) => {
        return current.length > maxLength ? current.length : maxLength;
    }, 0);

    const getPointSize = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([1, 10]);
        return scale(value);
    };

    const getOpacity = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([0.1, 1]);
        return scale(value);
    };

    useLayoutEffect(() => {
        d3.select(ref.current).selectAll('*').remove();

        const xVals = data.map(d => d[xAxes]);

        const yVals = data[0].map((_, i) => {
            if (i !== xAxes) {
                return data.map(row => row[i]);
            }
            return null;
        }).filter(yVal => yVal !== null);

        const margin = { top: 30, right: 30, bottom: 50, left: 40 };

        let width = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200) - margin.left - margin.right;
        let height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;

        if (legendPosition === 'top') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.top += 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        } else if (legendPosition === 'right') {
            margin.right += longestLabel * 10;
            width = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200) - margin.left - margin.right;
        } else if (legendPosition === 'bottom') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.bottom += 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        } else if (legendPosition === 'top-right') {
            margin.top += headers.length * 20;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - margin.top - margin.bottom - 10;
        }

        const uniqueId = uuidv4();

        const svg = d3.select(ref.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .attr('id', 'linechart-svg-group' + uniqueId);

        const xScale = d3.scaleLinear().domain([xMin - 0.1, xMax + 0.1]).range([0, width]);
        const yScale = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        if (showXGridLines) {
            svg.append('g')
                .attr('class', 'x axis-grid')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));
        }
        if (showYGridLines) {
            svg.append('g')
                .attr('class', 'y axis-grid')
                .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));
        }

        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis);
        svg.append('g')
            .attr('class', 'y axis')
            .attr('id', 'y-axis' + uniqueId)
            .call(yAxis);

        const colorScale = (index) => colorScheme[index % colorScheme.length];

        const scatterPoints = [];
        yVals.forEach((col, colIdx) => {
            col.forEach((entry, entryIdx) => {
                if (xVals[entryIdx] !== undefined && !isNaN(xVals[entryIdx]) && entry !== undefined && !isNaN(entry)) {
                    scatterPoints.push({ x: xVals[entryIdx], y: entry, color: colorScale(colIdx) });
                } else {
                    scatterPoints.push({ x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY });
                }
            });
        });

        scatterPoints.forEach(point => {
            if (
                (point.x === Number.POSITIVE_INFINITY || point.y === Number.POSITIVE_INFINITY) ||
                (point.x < xMin || point.x > xMax) ||
                (point.y < yMin || point.y > yMax)) {
                console.log('skipped');
            } else {
                if (pointType === 'circle') {
                    svg.append('circle')
                        .style('stroke', point.color)
                        .style('fill', point.color)
                        .attr('r', getPointSize(pointSize))
                        .attr('cx', xScale(point.x))
                        .attr('cy', yScale(point.y))
                        .style('opacity', getOpacity(opacity));
                } else if (pointType === 'rect') {
                    svg.append('rect')
                        .style('stroke', point.color)
                        .style('fill', point.color)
                        .attr('width', getPointSize(pointSize) * 2)
                        .attr('height', getPointSize(pointSize) * 2)
                        .attr('x', xScale(point.x) - getPointSize(pointSize))
                        .attr('y', yScale(point.y) - getPointSize(pointSize))
                        .style('opacity', getOpacity(opacity));
                } else if (pointType === 'triangle') {
                    svg.append('path')
                        .style('stroke', point.color)
                        .style('fill', point.color)
                        .attr('d', d3.symbol().type(d3.symbolTriangle).size(Math.pow(getPointSize(pointSize), 2) * 5))
                        .attr('transform', `translate(${xScale(point.x)}, ${yScale(point.y)})`)
                        .style('opacity', getOpacity(opacity));
                }
            }
        });

        // Adding the legend
        const lineLegend = svg.selectAll('.lineLegend').data(headers)
            .enter().append('g')
            .attr('class', 'lineLegend')
            .attr('transform', (d, i) => `translate(${width - 40},${i * 20})`);

        lineLegend.append('text')
            .text(d => d)
            .attr('transform', 'translate(15,9)');

        lineLegend.append('rect')
            .attr('fill', (d, i) => colorScale(i))
            .attr('width', 10)
            .attr('height', 10);

        // Adjust legend position based on legendPosition prop
        if (legendPosition === 'top') {
            const legendWidth = Math.min(width, headers.length * (longestLabel * 10 + 20));
            const startX = (width - legendWidth) / 2;
            lineLegend.attr('transform', (d, i) => {
                const x = startX + (i % Math.floor(width / (longestLabel * 20))) * (longestLabel * 10 + 20);
                const y = Math.floor(i / Math.floor(width / (longestLabel * 20))) * 20 - margin.top + 10;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'bottom') {
            const legendWidth = Math.min(width, headers.length * (longestLabel * 10 + 20));
            const startX = (width - legendWidth) / 2;
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            lineLegend.attr('transform', (d, i) => {
                const x = startX + (i % itemsPerRow) * (longestLabel * 10 + 20);
                const y = height + 40 + Math.floor(i / itemsPerRow) * 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'right') {
            const legendHeight = Math.min(height, headers.length * 20);
            const startY = (height - legendHeight) / 2;
            lineLegend.attr('transform', (d, i) => {
                const x = width + 5;
                const y = startY + (i % Math.floor(height / 20)) * 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'top-right') {
            lineLegend.attr('transform', (d, i) => `translate(${width - longestLabel * 10},${-30 - (i * 20)})`);
        }

        svg.append('text')
            .attr('class', 'x label')
            .attr('text-anchor', 'middle')
            .attr('x', width / 2)
            .attr('y', height + 30)
            .style('font-size', '0.75em')
            .text(xLabel);

        // Get bounding box of the <g> element
        const outerGroup = document.getElementById('linechart-svg-group' + uniqueId);
        const yAx = document.getElementById('y-axis' + uniqueId);

        const yAxWidth = yAx.getBoundingClientRect().width;

        // Calculate translation to center the <g> element
        const translateX = (0 + yAxWidth + margin.left);
        const translateY = (margin.top);

        // Apply the transformation
        outerGroup.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${0.9})`);
        // dynamic y-axis label positioning
        svg.append('text')
            .attr('class', 'y label')
            .attr('text-anchor', 'middle')
            .style('font-size', '0.75em')
            .attr('x', -height / 2)
            .attr('y', -translateX + 0.2 * margin.left)
            .attr('dy', '.75em')
            .attr('transform', 'rotate(-90)')
            .attr('id', 'y-axis-label' + uniqueId)
            .text(yLabel);
    }, [
        parentRef.current ? d3.select(parentRef.current.parentElement).node().offsetWidth : 200,
        JSON.stringify(data),
        JSON.stringify(headers),
        xAxes,
        xLabel,
        yLabel,
        yMin,
        yMax,
        xMin,
        xMax,
        showXGridLines,
        showYGridLines,
        legendPosition,
        pointSize,
        pointType,
        opacity,
        colorScheme,
        longestLabel
    ]);

    return (
        <svg ref={ref} data-testid="scatterplot" ></svg>
    );
};

export default ScatterPlot;
