import React, { useRef, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import { v4 as uuidv4 } from 'uuid';

const GroupedBarChart = ({
    dataArray,
    headers,
    xAxisCategories,
    parentRef,
    xLabel,
    yLabel,
    yMin,
    yMax,
    legendPosition,
    barWidth,
    opacity,
    colorScheme
}) => {
    const ref = useRef();
    const data = dataArray;
    const longestLabel = headers.reduce((maxLength, current) => {
        return current.length > maxLength ? current.length : maxLength;
    }, 0);

    const getBarWidth = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([0.1, 1]);
        return scale(value);
    };

    const getOpacity = (value) => {
        const scale = d3.scaleLinear().domain([1, 10]).range([0.1, 1]);
        return scale(value);
    };

    useLayoutEffect(() => {
        d3.select(ref.current).selectAll('*').remove();
        const parentWidth = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200);
        const parentHeight = parseInt(parentRef.current ? parentRef.current.offsetHeight - 5 : 200);
        const margin = { top: 40, right: 30, bottom: 40, left: 40 };
        let width = parentWidth - margin.left - margin.right;
        let height = parentHeight - margin.top - margin.bottom - 15;
        if (legendPosition === 'top') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.top = margin.top + 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - (margin.top) - margin.bottom - 20;
        } else if (legendPosition === 'right') {
            margin.right = margin.right + longestLabel * 10;
            width = parseInt(parentRef.current ? parentRef.current.offsetWidth : 200) - margin.left - margin.right;
        } else if (legendPosition === 'bottom') {
            const itemsPerRow = Math.floor(width / (longestLabel * 10 + 20));
            const numRows = Math.ceil(headers.length / itemsPerRow);
            margin.bottom = margin.bottom + 20 * numRows;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - (margin.top) - margin.bottom - 40;
        } else if (legendPosition === 'top-right') {
            margin.top = margin.top + headers.length * 20;
            height = parseInt(parentRef.current ? parentRef.current.offsetHeight : 200) - (margin.top) - margin.bottom - 20;
        }

        // Check if (yMin, yMax) range is still applicable
        const newYMin = d3.min(data.map(row => d3.min(row)));
        const newYMax = d3.max(data.map(row => d3.max(row)));
        if (yMin > newYMin) yMin = newYMin;
        if (yMax < newYMax) yMax = newYMax;

        const uniqueId = uuidv4();

        const svg = d3.select(ref.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .attr('id', 'linechart-svg-group' + uniqueId);

        const colorScale = (index) => colorScheme[index % colorScheme.length];

        const x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1)
            .domain((xAxisCategories && xAxisCategories.length > 0)
                ? xAxisCategories.map(val => val)
                : data.map(e => data.indexOf(e)));

        const x1 = d3.scaleBand()
            .padding(1 - getBarWidth(barWidth))
            .domain(data[0].map((_, i) => i))
            .rangeRound([0, x0.bandwidth()]);

        const y = d3.scaleLinear()
            .rangeRound([height, 0])
            .domain([yMin, yMax]);

        svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x0));

        svg.append('g')
            .attr('class', 'axis')
            .call(d3.axisLeft(y))
            .attr('id', 'y-axis' + uniqueId);

        svg.append('g')
            .attr('class', 'gridLine')
            .call(d3.axisLeft(y)
                .ticks(y.ticks().length)
                .tickSize(-width)
                .tickFormat('')
            );

        svg.append('g')
            .selectAll('rect')
            .data(data.flatMap((e1, e1Idx) => e1.map((e2, e2Idx) => {
                return { row: e1Idx, col: e2Idx, value: (!isNaN(e2) ? e2 : NaN) };
            })))
            .enter().append('rect')
            .attr('transform', function (d) {
                return 'translate(' + x0((xAxisCategories && xAxisCategories.length > 0) ? xAxisCategories[d.row] : d.row) + ',0)';
            })
            .attr('data-testid', 'bar')
            .attr('x', function (d) { return x1(d.col); })
            .attr('y', function (d) { return d.value < 0 ? y(0) : y(d.value); })
            .attr('width', x1.bandwidth())
            .attr('height', function (d) { return y(0) - y(Math.abs(d.value)); })
            .attr('fill', function (d) { return colorScale(d.col); })
            .style('opacity', getOpacity(opacity));

        svg.append('text')
            .attr('class', 'x label')
            .attr('text-anchor', 'middle')
            .attr('x', (width / 2))
            .attr('y', height + 30)
            .style('font-size', '0.75em')
            .text(xLabel);

        // Get bounding box of the <g> element
        const outerGroup = document.getElementById('linechart-svg-group' + uniqueId);
        const yAx = document.getElementById('y-axis' + uniqueId);

        const yAxWidth = yAx.getBoundingClientRect().width;

        // Calculate translation to center the <g> element
        const translateX = (0 + yAxWidth + margin.left);
        const translateY = (margin.top);

        // Apply the transformation
        outerGroup.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${0.9})`);

        svg.append('text')
            .attr('class', 'y label')
            .attr('text-anchor', 'middle')
            .style('font-size', '0.75em')
            .attr('x', -height / 2)
            .attr('y', -translateX + 0.2 * margin.left)
            .attr('dy', '.75em')
            .attr('transform', 'rotate(-90)')
            .attr('id', 'y-axis-label' + uniqueId)
            .text(yLabel);

        // Dynamic legend
        const legend = svg.selectAll('.legend')
            .data(headers)
            .enter().append('g')
            .attr('class', 'legend');

        legend.append('rect')
            .attr('width', 15)
            .attr('height', 15)
            .style('fill', (d, i) => colorScale(i))
            .style('opacity', getOpacity(opacity));

        legend.append('text')
            .attr('x', 25)
            .attr('y', 12)
            .style('text-anchor', 'start')
            .style('font-size', '1em')
            .text(d => d);

        // Position the legend based on the legendPosition prop
        const measureTextWidth = (text, fontSize) => {
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.visibility = 'hidden';
            div.style.fontSize = fontSize;
            div.textContent = text;
            document.body.appendChild(div);
            const width = div.offsetWidth;
            document.body.removeChild(div);
            return width;
        };

        const legendLabelMaxLen = d3.max(headers.map(header => {
            return measureTextWidth(header, '1em');
        }));

        if (legendPosition === 'top') {
            const legendWidth = Math.min(width, headers.length * (legendLabelMaxLen + 30)); // Breiteren Abstand berücksichtigen
            const startX = (width - legendWidth) / 2;
            legend.attr('transform', function (d, i) {
                const x = startX + (i % Math.floor(width / (legendLabelMaxLen + 30))) * (legendLabelMaxLen + 30);
                const y = Math.floor(i / Math.floor(width / (legendLabelMaxLen + 30))) * 25 - margin.top + 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'bottom') {
            const legendWidth = Math.min(width, headers.length * (legendLabelMaxLen + 30));
            const startX = (width - legendWidth) / 2;
            const itemsPerRow = Math.floor(width / (legendLabelMaxLen + 30));
            legend.attr('transform', function (d, i) {
                const x = startX + (i % itemsPerRow) * (legendLabelMaxLen + 30);
                const y = height + 50 + Math.floor(i / itemsPerRow) * 25; // Y-Position leicht nach unten verschieben
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'right') {
            const legendHeight = Math.min(height, headers.length * 20);
            const startY = (height - legendHeight) / 2;
            legend.attr('transform', function (d, i) {
                const x = width + 3;
                const y = startY + (i % Math.floor(height / 20)) * 20;
                return `translate(${x}, ${y})`;
            });
        } else if (legendPosition === 'top-right') {
            legend.attr('transform', function (d, i) {
                return `translate(${width - legendLabelMaxLen - 20}, ${-30 - (i * 20)})`;
            });
        }
    }, [
        parentRef.current ? d3.select(parentRef.current.parentElement).node().offsetWidth : 200,
        JSON.stringify(data),
        JSON.stringify(headers),
        xLabel,
        yLabel,
        yMin,
        yMax,
        legendPosition,
        barWidth,
        opacity,
        colorScheme

    ]);

    return (
        <svg ref={ref} data-testid='groupedbarchart'></svg>
    );
};

export default GroupedBarChart;
