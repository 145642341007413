import * as d3 from 'd3';
import { getMaxNumericArrayValue } from '../../../../services/utils';
import { DIAGRAM_TYPES } from './DiagramTypes/diagramTypes';

export function processSpreadsheetData ({ spreadsheet, diagramInfo, currentRows, currentCols, xAxes, currentType }) {
    const diagramProperties = JSON.parse(diagramInfo.settings);
    const isScatterOrLineChart = diagramInfo.type === DIAGRAM_TYPES.SCATTERPLOTCHART || diagramInfo.type === DIAGRAM_TYPES.LINECHART;

    const filteredDataY = currentRows.map(function (rowIdx) {
        return currentCols
            .filter(function (colIdx) {
                return isScatterOrLineChart ? colIdx !== xAxes : true; // Filter xAxes only for scatter/line chart
            })
            .map(function (colIdx) {
                return spreadsheet.data[rowIdx] && spreadsheet.data[rowIdx][colIdx]
                    ? spreadsheet.data[rowIdx][colIdx].value !== ''
                        ? Number(spreadsheet.data[rowIdx][colIdx].value)
                        : undefined
                    : undefined;
            })
            .filter(function (value) {
                return value !== undefined;
            });
    });

    const yValsTransposed = filteredDataY[0].map((col, i) => filteredDataY.map(row => row[i]));

    const filteredDataX = currentRows
        .filter(rowIdx => spreadsheet.data[rowIdx] && spreadsheet.data[rowIdx][xAxes]) // Ignore undefined values
        .map(rowIdx => spreadsheet.data[rowIdx][xAxes].value
            ? Number(spreadsheet.data[rowIdx][xAxes].value)
            : undefined
        ); // Ensure value is a number

    let yMin = 0;
    let yMax = 0;
    let xMin = 0;
    let xMax = 0;

    switch (diagramInfo.type) {
    case DIAGRAM_TYPES.SCATTERPLOTCHART:
    case DIAGRAM_TYPES.LINECHART:
        xMin = d3.min(filteredDataX.flat().map(x => x === '' ? Number.POSITIVE_INFINITY : Number(x)));
        xMax = d3.max(filteredDataX.flat().map(x => x === '' ? Number.NEGATIVE_INFINITY : Number(x)));
        yMin = d3.min(yValsTransposed.flat().map(x => x === '' ? Number.POSITIVE_INFINITY : Number(x)));
        yMax = d3.max(yValsTransposed.flat().map(x => x === '' ? Number.NEGATIVE_INFINITY : Number(x)));
        break;

    case DIAGRAM_TYPES.STACKEDBARCHART:
        filteredDataY.forEach(row => {
            const rowYMin = d3.sum(row.filter(e => e < 0).map(Number)); // Ensure sum uses numbers
            const rowYMax = d3.sum(row.filter(e => e >= 0).map(Number)); // Ensure sum uses numbers;
            if (rowYMin < yMin) yMin = rowYMin;
            if (rowYMax > yMax) yMax = rowYMax;
        });
        break;

    case DIAGRAM_TYPES.GROUPEDBARCHART:
        yMin = d3.min(yValsTransposed.flat().map(Number)) >= 0 ? 0 : d3.min(yValsTransposed.flat().map(Number));
        yMax = getMaxNumericArrayValue(yValsTransposed.flat().map(Number)); // Ensure values are numbers
        break;

    default:
        break;
    }

    const currentyMin = diagramProperties.yMin;
    const currentyMax = diagramProperties.yMax;
    const currentxMin = diagramProperties.xMin;
    const currentxMax = diagramProperties.xMax;

    if (currentType === diagramInfo.type && currentyMin !== undefined && currentyMin !== null && currentyMin !== '') {
        yMin = parseFloat(currentyMin);
    }
    if (currentType === diagramInfo.type && currentyMax !== undefined && currentyMax !== null && currentyMax !== '') {
        yMax = parseFloat(currentyMax);
    }
    if (currentType === diagramInfo.type && currentxMin !== undefined && currentxMin !== null && currentxMin !== '') {
        xMin = parseFloat(currentxMin);
    }
    if (currentType === diagramInfo.type && currentxMax !== undefined && currentxMax !== null && currentxMax !== '') {
        xMax = parseFloat(currentxMax);
    }
    return {
        xMin: xMin,
        xMax: xMax,
        yMin: yMin,
        yMax: yMax
    };
}
