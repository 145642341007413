import React, { useState, useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { selectBoard } from '../../model/features/boards/boardsSlice';
import logo from '../../assets/images/logoBASF/BASFLogo-TIF(RGB)/linkBASF.svg';
import { forEach } from 'lodash';
import Modal from 'react-modal';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import NativeSelect from '@mui/material/NativeSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Button } from '@mui/material';
import {
    SI_MODAL_STYLE,
    SI_CONTAINER_STYLE,
    MIDDLE_ROW_CONTAINER_STYLE,
    ACTION_BUTTON_STYLE,
    useToolboxStyles
} from './styles';
import { USER_ROLES } from '../users/users';

export default function ExportBoard ({ boardId, contextManager, closeTabBoard, triggerEvent, HtmlExportModalOpen, setHtmlExportModalOpen }) {
    const { showBoundary } = useErrorBoundary();
    const classes = useToolboxStyles();
    // const [HtmlExportModalOpen, setHtmlExportModalOpen] = useState(false);
    const isAdminUser = contextManager.userRole === 'a';
    const [selectedExperiment, setSelectedExperiment] = useState(contextManager.experiments[0] || null);
    const [state, setState] = React.useState({
        Skript: false,
        Tafel: false,
        Notizen: false
    });
    const { Skript, Tafel, Notizen } = state;

    const setSkript = (newSkript) => {
        setState(prevState => ({ ...prevState, Skript: newSkript }));
    };

    const setTafel = (newTafel) => {
        setState(prevState => ({ ...prevState, Tafel: newTafel }));
    };

    const setNotizen = (newNotizen) => {
        setState(prevState => ({ ...prevState, Notizen: newNotizen }));
    };

    const closeModal = () => {
        setHtmlExportModalOpen(false);
        setSkript(false);
        setTafel(false);
        setNotizen(false);
    };

    const handleCheckmarkChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked
        });
    };

    const handleExperimentSelectChange = (event) => {
        const id = parseInt(event.target.value);
        const experiment = contextManager.experiments.find(exp => exp.id === id);
        setSelectedExperiment(experiment);
    };

    async function toDataURL (imageUrl) {
        try {
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => {
                    console.error('Error reading the image:', error);
                    reject(error);
                };
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image to Base64:', error);
            throw error;
        }
    }

    const getSrcComponent = (imgTag) => {
        let result = '';
        for (let i = 0; i < imgTag.length - 4; i++) {
            if (imgTag.charAt(i) === 's' &&
                imgTag.charAt(i + 1) === 'r' &&
                imgTag.charAt(i + 2) === 'c' &&
                imgTag.charAt(i + 3) === '=' &&
                (imgTag.charAt(i + 4) === "'" || imgTag.charAt(i + 4) === '"')) {
                for (let j = i + 5; j < imgTag.length; j++) {
                    if (imgTag.charAt(j) === "'" || imgTag.charAt(j) === '"') {
                        break;
                    } else {
                        result += imgTag.charAt(j);
                    }
                }
            }
        }
        return result;
    };

    const buildAndDownloadFile = (htmlContent, boardType) => {
        // Create a blob with the HTML content
        const blob = new Blob([htmlContent], { type: 'text/html' });
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Board-${boardType}.html`;
        // Append the link to the body, click it to trigger the download, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    async function prepareHtmlString (index) {
        await contextManager.setTabValue(index);
        await contextManager.getAllComponentsIntoView();
        let htmlContent = document.documentElement.outerHTML;
        // htmlContent = htmlContent.replaceAll('overflow:hidden;', 'overflow:scroll;');
        // htmlContent = htmlContent.replaceAll('overflow-x:hidden;', 'overflow-x:scroll;');
        // htmlContent = htmlContent.replaceAll('overflow-y:hidden;', 'overflow-y:hidden;');
        htmlContent = htmlContent.replaceAll('<div data-testid="Text" style="height: -webkit-fill-available; width: 100%; margin: 0px; padding: 0px; position: absolute; overflow: auto;"><div id="editor" class="ql-container ql-snow ql-disabled">',
            '<div data-testid="Text" style="height: 93%; width: 100%; margin: 0px; padding: 0px; position: absolute; overflow: auto;"><div id="editor" class="ql-container ql-snow ql-disabled">');
        htmlContent = htmlContent.replaceAll('overflow: clip;', '');
        htmlContent = htmlContent.replace('<body class="">', '<body class=""><div id="overflowDiv">');
        htmlContent = htmlContent.replace('</body>', '</div></body>');
        const imgElements = document.getElementsByTagName('img');
        const imgOuterHtml = Array.from(imgElements).map(img => img.outerHTML);
        const imgSources = Array.from(imgOuterHtml).map(element => getSrcComponent(element));
        // replace all external image links with their base64 encoding
        for (const element of imgSources) {
            if (!element.includes('/static/media/linkBASF') && !element.includes('base64')) {
                try {
                    const base64 = await toDataURL(element);
                    htmlContent = htmlContent.replace(element, base64);
                } catch (error) {
                }
            } else if (element.includes('/static/media/linkBASF')) {
                try {
                    const base64 = await toDataURL(logo);
                    htmlContent = htmlContent.replace(element, base64);
                } catch (error) {
                }
            }
        }
        const script = `\n<head>
        <script>
        document.body.style.zoom = "0.9";
        function zoomInAction () {
            const currentZoom = parseFloat(document.body.style.zoom) || 1;
            const newZoom = currentZoom + 0.1;
            document.body.style.zoom = newZoom.toFixed(1);
        }
        function zoomOutAction () {
            const currentZoom = parseFloat(document.body.style.zoom) || 1;
            const newZoom = currentZoom - 0.1;
            if (newZoom >= 0.1){
                document.body.style.zoom = newZoom.toFixed(1);
            }
        }
        function resetZoomAction () {
            document.body.style.zoom = "0.9";
        }
            
    </script>

    <style>
        body{
          min-width: 100vw;
          overflow-y: auto !important;
          overflow-x: auto !important;
        }
    </style>\n`;
        htmlContent = htmlContent.replace('overflow: hidden !important;', '');
        const btnElements = document.getElementsByTagName('button');
        const btnOuterHtml = Array.from(btnElements).map(btn => btn.outerHTML);
        btnOuterHtml.forEach(element => {
            if (element.includes('>+<')) {
                const zoomInButton = element.replace('button ', 'button id="zoomIn" onclick="zoomInAction()" ');
                htmlContent = htmlContent.replace(element, zoomInButton);
            }
            if (element.includes('>-<')) {
                const zoomOutButton = element.replace('button ', 'button id="zoomOut" onclick="zoomOutAction()" ');
                htmlContent = htmlContent.replace(element, zoomOutButton);
            }
            if (element.includes('VisibilityIcon')) {
                const zoomOutButton = element.replace('button ', 'button id="resetZoom" onclick="resetZoomAction()" ');
                htmlContent = htmlContent.replace(element, zoomOutButton);
            } else {
                htmlContent = htmlContent.replace(element, '');
            }
        });
        htmlContent = htmlContent.replace('<head>', script);
        htmlContent = htmlContent.replace(selectedExperiment.name, script);
        htmlContent = htmlContent.replace('<path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>', '');
        return htmlContent;
    }

    async function downloadBoards () {
        setHtmlExportModalOpen(false);
        await closeTabBoard();
        contextManager.setActiveExperiment(selectedExperiment);
        for (let i = 0; i < 3; i++) {
            try {
                const header = '<hr class="MuiDivider-root MuiDivider-middle MuiDivider-vertical MuiDivider-flexItem css-16bghm4-MuiDivider-root">';
                let boardType = '';
                const date = new Date();
                const month = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                switch (i) {
                case 0:
                    if (Skript) {
                        const htmlContent = await prepareHtmlString(i);
                        boardType = 'Skript';
                        const title = `<span style="font-weight: bold;">${boardType} - ${selectedExperiment.name} - ${contextManager.userId} - ${date.getDate()}/${month[date.getMonth()]}/${date.getFullYear()}</span>`;
                        buildAndDownloadFile(htmlContent.replace(header, header + title), boardType);
                    }
                    break;
                case 1:
                    if (Notizen) {
                        const htmlContent = await prepareHtmlString(i);
                        boardType = 'Notizen';
                        const title = `<span style="font-weight: bold;">${boardType} - ${selectedExperiment.name} - ${contextManager.userId} - ${date.getDate()}/${month[date.getMonth()]}/${date.getFullYear()}</span>`;
                        buildAndDownloadFile(htmlContent.replace(header, header + title), boardType);
                    }
                    break;
                default:
                    if (Tafel) {
                        const htmlContent = await prepareHtmlString(i);
                        boardType = 'Tafel';
                        const title = `<span style="font-weight: bold;">${boardType} - ${selectedExperiment.name} - ${contextManager.userId} - ${date.getDate()}/${month[date.getMonth()]}/${date.getFullYear()}</span>`;
                        buildAndDownloadFile(htmlContent.replace(header, header + title), boardType);
                    }
                    break;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        setSkript(false);
        setTafel(false);
        setNotizen(false);
    }

    return (
        <span className={classes.root}>
            <span onClick={(e) => { try { setHtmlExportModalOpen(true); } catch (err) { showBoundary(err); } }}>Html Export</span>
            <Modal
                id='html-export-modal'
                data-testId='html-export-modal'
                isOpen={HtmlExportModalOpen}
                contentLabel='URLUploadModal'
                style={SI_MODAL_STYLE}
                parentSelector={() => (document.getElementsByClassName('fullscreen') ? document.getElementsByClassName('fullscreen')[0] : undefined)}
            >
                <div style={SI_CONTAINER_STYLE}>
                    <Box sx={{ minWidth: 220 }}>
                        <FormControl fullWidth>
                            {selectedExperiment && selectedExperiment.id && (
                                <NativeSelect
                                    value={selectedExperiment.id}
                                    onChange={handleExperimentSelectChange}
                                >
                                    {contextManager.experiments.length !== 0 &&
                                    Object.entries(contextManager.experiments).map(([experimentId, experiment]) => (
                                        <option key={experiment.id} value={experiment.id}>
                                            {experiment.name}
                                        </option>
                                    ))}
                                </NativeSelect>
                            )}
                            <div style={{ height: '20px' }} />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={Skript} onChange={handleCheckmarkChange} name="Skript"/>} label="Skript"/>
                                <FormControlLabel control={<Checkbox checked={Notizen} onChange={handleCheckmarkChange} name="Notizen"/>} label="Notizen"/>
                                <FormControlLabel control={<Checkbox checked={Tafel} onChange={handleCheckmarkChange} name="Tafel"/>} label="Tafel"/>
                            </FormGroup>
                            <div style={{ height: '10px' }} />
                            <div>
                                <Button
                                    onClick={downloadBoards}
                                    disabled={!(Skript || Notizen || Tafel)}
                                    style={ACTION_BUTTON_STYLE}
                                    size='small'
                                    variant='contained'
                                    color='secondary'
                                    aria-label='small contained secondary button'
                                >
                                    EXPORTIEREN
                                </Button>
                                <Button
                                    onClick={closeModal}
                                    size='small'
                                    variant='contained'
                                    style={ACTION_BUTTON_STYLE}
                                    aria-label='small contained button'
                                >
                                    VERWERFEN
                                </Button>
                            </div>
                        </FormControl>
                    </Box>
                </div>
            </Modal>
        </span>
    );
}
