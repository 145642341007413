import React, { useState } from 'react';
import DiagramSettingsProperties from './DiagramSettingProperties';
import DiagramSettingLinesProperties from './DiagramSettingLinesProperties';
import DiagramSettingColor from './DiagramSettingColor';
import Modal from 'react-modal';
import {
    DIAGRAM_SETTINGS_MODAL_CONTAINER_STYLE,
    DIAGRAM_ICON_SETTINGS
} from '../styles';
import SettingsIcon from '@mui/icons-material/Settings';

export default function DiagramSettings ({
    docName,
    boardId,
    containerId,
    contextManager,
    diagramInfo,
    setShowDiagramVisual,
    setIsSettingsOpen,
    spreadsheet
}) {
    const [showLinesSettings, setShowLinesSettings] = useState(false);
    const [showColorSchemeSettings, setShowColorSchemeSettings] = useState(false);
    // DiagramSettingsProperties - Component used for selection of diagram type and the spreadsheet to retrieve data from
    const chooseSettingsForm = () => {
        if (showLinesSettings) {
            return <DiagramSettingLinesProperties docName={docName} boardId={boardId} containerId={containerId} contextManager={contextManager} diagramInfo={diagramInfo} setShowDiagramVisual={setShowDiagramVisual} setShowLinesSettings={setShowLinesSettings} />;
        } else if (showColorSchemeSettings) {
            return <DiagramSettingColor docName={docName} diagramInfo={diagramInfo} contextManager={contextManager} setShowColorSchemeSettings={setShowColorSchemeSettings}/>;
        } else {
            return <DiagramSettingsProperties docName={docName} boardId={boardId} containerId={containerId} contextManager={contextManager} diagramInfo={diagramInfo} setShowDiagramVisual={setShowDiagramVisual} setShowLinesSettings={setShowLinesSettings} setShowColorSchemeSettings={setShowColorSchemeSettings} setIsSettingsOpen={setIsSettingsOpen} spreadsheet={spreadsheet} />;
        }
    };

    return (
        <>
            <div style={DIAGRAM_ICON_SETTINGS.content}>
                <SettingsIcon style={{ fontSize: '20rem' }} />
            </div><Modal
                data-testId='diagramm-settings-modal'
                isOpen={true}
                contentLabel='Diagramm Settings'
                style={DIAGRAM_SETTINGS_MODAL_CONTAINER_STYLE}
            >
                {chooseSettingsForm()}
            </Modal>
        </>
    );
}
