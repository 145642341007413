import { addComponent, moveComponent, removeComponent } from '../../model/features/boards/boardsSlice';
import { removeFlag, removeProgressTrackerItem } from '../../model/features/personaldata/personaldataSlice';
import { generatePseudoRandomId } from '../../services/ids';
import { prepareDiagram } from './diagramHelpers'; // Import from your new file
import { TOOL_TYPES } from '../Tools/toolTypes';

export function getMoveBox (dispatch, boardId) {
    return (id, left, top, sync = true) => {
        dispatch(moveComponent({ id: boardId, componentId: id, position: { x: left, y: top }, sync }));
    };
}

export function getAddBox (dispatch, boardId, contextManager) {
    return (type, left, top, id, dataId, size, createdBy, createdOn, description, copied, diagramType, diagramSsid, spreadsheet) => {
        dataId = dataId || generatePseudoRandomId();
        createdBy = createdBy || contextManager.userId;
        createdOn = createdOn || (new Date()).toLocaleDateString('en-DE', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
        const componentName = (contextManager.toolList.find(tool => tool.type === type)?.name);
        dispatch(addComponent({ id: boardId, type, innerId: dataId, position: { x: left, y: top }, componentInfo: { componentId: id, size }, createdBy, createdOn, componentName, description }));
        contextManager.addRecentlyUsedTool(type);
        if (type === TOOL_TYPES.DIAGRAM && !copied) {
            prepareDiagram(contextManager, boardId, dataId, type, diagramType, diagramSsid, spreadsheet);
        }
    };
}

export function getRemoveBox (dispatch, boardId) {
    return (id) => {
        dispatch(removeComponent({ id: boardId, componentId: id }));
        dispatch(removeFlag(boardId, id));
        dispatch(removeProgressTrackerItem(boardId, id));
    };
}
