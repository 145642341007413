import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    setDiagramSettings as persistDiagramSettings,
    setDiagramType as persistDiagramType
} from '../../../../../model/features/diagrams/diagramsSlice';
import { DIAGRAM_TYPES, DIAGRAM_CONFIG } from '../DiagramTypes/diagramTypes.js';
import {
    Button,
    TextField,
    FormControl,
    Grid,
    Divider,
    Typography,
    Switch,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tabs,
    Tab,
    useTheme
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
    SETTINGS_CONTAINER_STYLE,
    FORWARD_BTN_STYLE
} from '../styles';
import { useErrorBoundary } from 'react-error-boundary';
import { processSpreadsheetData } from '../diagramSpreadsheetUtils.js';

export default function DiagramSettingsProperties ({
    docName,
    boardId,
    containerId,
    diagramInfo,
    contextManager,
    setShowDiagramVisual,
    setShowLinesSettings,
    setShowColorSchemeSettings,
    setIsSettingsOpen,
    spreadsheet
}) {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();

    // stores the new type value for the diagram
    const [type, setTyp] = useState(diagramInfo.type);
    // stores indices of selected columns (default: all columns)
    const [rows, setRows] = useState([]);
    // stores indices of selected columns (default: all columns)
    const [cols, setCols] = useState([]);
    // stores the column used for categorical representation (E.g: In BarCharts)
    // First column of the spreadsheet will be considered by default
    const [categoryCol, setCategoryCol] = useState(-1);
    // stores which column should be used as x-axis
    const [xAxes, setXAxes] = useState(0);
    // stores x-axis label (default: first header)
    const [xLabel, setXLabel] = useState('x-Achse');
    // stores y-axis label
    const [yLabel, setYLabel] = useState('y-Achse');
    // stores lower boundary for x-axis values
    const [xMin, setXMin] = useState();
    // stores upper boundary for x-axis values
    const [xMax, setXMax] = useState();
    // stores lower boundary for y-axis values
    const [yMin, setYMin] = useState();
    // stores upper boundary for y-axis values
    const [yMax, setYMax] = useState();
    // controls visibility of x-axis grid lines
    const [showXGridLines, setShowXGridLines] = useState(true);
    // controls visibility of y-axis grid lines
    const [showYGridLines, setShowYGridLines] = useState(true);
    // stores legendPosition
    const [legendPosition, setLegendPosition] = useState('top-right');
    // stores dropdown states for multiple dropdowns added to DOM
    const [viewDropdown, setViewDropdown] = useState({});

    const diagramProperties = JSON.parse(diagramInfo.settings);

    const [activeTab, setActiveTab] = useState(0);
    const theme = useTheme(); // Zugriff auf das Thema

    // Error states for axis validation
    const [errors, setErrors] = useState({
        xMinError: false,
        xMaxError: false,
        yMinError: false,
        yMaxError: false
    });

    // Compute values using `useMemo` to prevent re-calculation on every render
    const computedValues = useMemo(() => {
        if (spreadsheet && diagramProperties) {
            const { rows, cols, xAxes } = diagramProperties;
            return processSpreadsheetData({ spreadsheet, diagramInfo, currentRows: rows, currentCols: cols, xAxes });
        }
        return { xMin: 0, xMax: 0, yMin: 0, yMax: 0 };
    }, [spreadsheet, diagramProperties]);

    useEffect(() => {
        if (Object.keys(diagramProperties).length > 0) {
            setRows(diagramProperties.rows);
            setCols(diagramProperties.cols);
            setCategoryCol(diagramProperties.categoryColumn);
            setXAxes(diagramProperties.xAxes);
            setXLabel(diagramProperties.xLabel);
            setYLabel(diagramProperties.yLabel);
            setXMin(Number(diagramProperties.xMin));
            setXMax(Number(diagramProperties.xMax));
            setYMin(Number(diagramProperties.yMin));
            setYMax(Number(diagramProperties.yMax));
            setShowXGridLines(diagramProperties.showXGridLines);
            setShowYGridLines(diagramProperties.showYGridLines);
            setLegendPosition(diagramProperties.legendPosition);
        } else {
            if (showCategoryColumnSelection()) {
                setCategoryCol(0);
                setCols(Array.from({ length: (spreadsheet.data[0].length - 1) }, (_, i) => i + 1)); // Create array skipping first column, with default selection of remaining columns from spreadsheet components
            }
        }
    }, [diagramInfo.settings]);

    const handleDoubleClick = (e) => {
        e.stopPropagation();
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const validateAxisInput = () => {
        /*
        const { xMin: computedXMin, xMax: computedXMax, yMin: computedYMin, yMax: computedYMax } = computedValues;
        // Collect validation errors in a temporary object
        const newErrors = {
            xMinError: xMin > computedXMin,
            xMaxError: xMax < computedXMax,
            yMinError: yMin > computedYMin,
            yMaxError: yMax < computedYMax
        };

        // Update the error state all at once
        setErrors(newErrors);

        // Return whether there are any errors
        */
        const newErrors = {};
        return Object.values(newErrors).some(error => error); // true if any error is present
    };

    // Save diagram settings saved and setFinsihed to true to display diagram
    const saveDiagramSettings = (event) => {
        // Validate all inputs at once
        const hasErrors = validateAxisInput();

        // If there are any validation errors, do not proceed
        if (hasErrors) {
            return;
        }
        if (type !== diagramInfo.type) {
            dispatch(persistDiagramType(docName, type));
        }
        event.preventDefault();
        // prepare diagramSettings from properties used above
        const diagramSettings = {
            rows: rows,
            cols: cols,
            categoryColumn: categoryCol,
            xAxes: xAxes,
            xLabel: xLabel,
            yLabel: yLabel,
            yMin: yMin,
            yMax: yMax,
            xMin: xMin,
            xMax: xMax,
            showXGridLines: showXGridLines,
            showYGridLines: showYGridLines,
            legendPosition: legendPosition,
            lineType: diagramProperties.lineType,
            lineThickness: diagramProperties.lineThickness,
            pointType: diagramProperties.pointType,
            pointSize: diagramProperties.pointSize,
            opacity: diagramProperties.opacity,
            barWidth: diagramProperties.barWidth,
            colorScheme: diagramProperties.colorScheme,
            colorOptions: diagramProperties.colorOptions
        };
        dispatch(persistDiagramSettings(docName, JSON.stringify(diagramSettings)));
        setShowDiagramVisual(true);
    };

    const isSaveDisabled = () => {
        if (rows && rows.length > 0 && cols && cols.length > 0 && yLabel && (yMin !== '' && yMin !== undefined && !isNaN(yMin) && Number.isFinite(yMin)) && (yMax !== '' && yMax !== undefined && !isNaN(yMax) && Number.isFinite(yMax))) {
            return false;
        }
        return true;
    };

    const legendPositionLabels = {
        Oben: 'top',
        Unten: 'bottom',
        Rechts: 'right',
        'Oben Rechts': 'top-right'
    };

    const showCategoryColumnSelection = () => {
        return [DIAGRAM_TYPES.GROUPEDBARCHART, DIAGRAM_TYPES.STACKEDBARCHART].includes(diagramInfo.type);
    };

    const isLineOrScatterplotChart = () => {
        return [DIAGRAM_TYPES.LINECHART, DIAGRAM_TYPES.SCATTERPLOTCHART].includes(diagramInfo.type);
    };

    return (
        <div style={SETTINGS_CONTAINER_STYLE} data-testId='diagramm-settings-properties' >
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                    marginBottom: '16px',
                    marginTop: '16px',
                    backgroundColor: theme.palette.primary.main, // Set the primary background color for the tabs container
                    borderRadius: '8px', // Adding rounded corners
                    border: '1px solid #111111', // Setting the outer border color
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.primary.main // Set the color of the tab indicator (underline of the active tab)
                    },
                    '& .MuiTab-root': {
                        borderRight: '1px solid #ffffff' // Set the border color between tabs
                    },
                    '& .MuiTab-root:last-child': {
                        borderRight: 'none' // Remove border on the last tab to avoid extra line
                    }
                }}
            >
                <Tab
                    label="Diagramtyp & Daten"
                    sx={{
                        backgroundColor: activeTab === 0 ? '#ffffff' : 'inherit', // Active tab has white background
                        color: activeTab === 0 ? theme.palette.primary.main : '#ffffff' // Active tab has primary color text, inactive has white text
                    }}
                />
                <Tab
                    label="Achsen Bearbeitung"
                    sx={{
                        backgroundColor: activeTab === 1 ? '#ffffff' : 'inherit',
                        color: activeTab === 1 ? theme.palette.primary.main : '#ffffff'
                    }}
                />
                <Tab
                    label={isLineOrScatterplotChart() ? 'Gitter und Legende' : 'Legende'}
                    sx={{
                        backgroundColor: activeTab === 2 ? '#ffffff' : 'inherit',
                        color: activeTab === 2 ? theme.palette.primary.main : '#ffffff'
                    }}
                />
            </Tabs>

            { activeTab === 0 && (<div>
                <Typography gutterBottom variant="body2" color='text.secondary'>
                        Diagramtyp einstellen
                </Typography>
                <Grid container spacing={2} columnSpacing={0}>
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth className='custom-select'>
                            <select
                                value={type}
                                label="type ändern"
                                id='typ_ändern'
                                data-testId='typ_ändern'
                                onChange={(event) => {
                                    try {
                                        setTyp((event.target.value));
                                    } catch (err) { showBoundary(err); }
                                }}
                            >
                                {
                                    DIAGRAM_CONFIG.map((diagram) => (
                                        <option key={diagram.id} value={diagram.id}>
                                            {diagram.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Typography gutterBottom variant="body2" color='text.secondary'>
                        Anzuzeigende Zeilen und Spalten
                </Typography>
                <Grid container spacing={2} columnSpacing={0}>
                    <Grid item xs={4}>
                        <FormControl variant="standard" fullWidth>
                            <label htmlFor="" className='custom-select-label'>Zeilen</label>
                            <div className="custom-multiselect" onClick={(e) => { try { setViewDropdown((prevState) => { return { ...prevState, zeilen: !prevState.zeilen }; }); } catch (err) { showBoundary(err); } }} data-testId='select-rows'>
                                <div className="custom-select">
                                    <select>
                                        <option>
                                            { (rows && rows.length > 0) ? rows.map(val => val + 1).toString() : 'Select an option' }
                                        </option>
                                    </select>
                                    <div className="custom-overSelect"></div>
                                </div>
                                <div className="custom-checkboxes" style={{ display: viewDropdown.zeilen ? 'block' : 'none', maxHeight: spreadsheet.data.length > 3 ? '100px' : 'auto', overflowY: spreadsheet.data.length > 3 ? 'auto' : 'visible' }}>
                                    {
                                        spreadsheet.data.map((_, rowIdx) => {
                                            return (
                                                <label key={rowIdx} className='custom-checkbox-container'>
                                                    <input
                                                        type="checkbox"
                                                        data-testId = 'row'
                                                        checked={rows.includes(rowIdx)}
                                                        value={rowIdx}
                                                        onClick={(event) => {
                                                            try {
                                                                setRows((prevState) => {
                                                                    const prevStateObj = [...prevState];
                                                                    if (event.target.checked) {
                                                                        prevStateObj.push(Number(event.target.value));
                                                                    } else if (!event.target.checked && (prevStateObj.indexOf(Number(event.target.value) > -1))) {
                                                                        prevStateObj.splice(prevStateObj.indexOf(Number(event.target.value)), 1);
                                                                    }
                                                                    return prevStateObj;
                                                                });
                                                            } catch (err) { showBoundary(err); }
                                                        }}
                                                    />
                                                    <span> {`Zeile ${rowIdx + 1}`} </span>
                                                    <span className='custom-checkmark'></span>
                                                </label>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="standard" fullWidth>
                            <label htmlFor="" className='custom-select-label'>Spalten</label>
                            <div className="custom-multiselect" onClick={(e) => { try { setViewDropdown((prevState) => { return { ...prevState, spalten: !prevState.spalten }; }); } catch (err) { showBoundary(err); } } } data-testId='select-cols'>
                                <div className="custom-select">
                                    <select>
                                        <option>
                                            { (cols && cols.length > 0) ? cols.map(colIdx => spreadsheet.headers[colIdx]).toString() : 'Select an option' }
                                        </option>
                                    </select>
                                    <div className="custom-overSelect"></div>
                                </div>
                                <div className="custom-checkboxes" style={{ display: viewDropdown.spalten ? 'block' : 'none', maxHeight: spreadsheet.headers.length > 3 ? '100px' : 'auto', overflowY: spreadsheet.headers.length > 3 ? 'auto' : 'visible' }}>
                                    {
                                        spreadsheet.data[0].map((_, colIdx) => {
                                            return (
                                                <label key={colIdx} className='custom-checkbox-container'>
                                                    <input
                                                        type="checkbox"
                                                        checked={cols.includes(colIdx)}
                                                        value={colIdx}
                                                        disabled={
                                                            isLineOrScatterplotChart()
                                                                ? (colIdx === xAxes && [DIAGRAM_TYPES.LINECHART, DIAGRAM_TYPES.SCATTERPLOTCHART].includes(diagramInfo.type))
                                                                : (categoryCol > -1 && colIdx === categoryCol)
                                                        }
                                                        onClick={(event) => {
                                                            try {
                                                                setCols((prevState) => {
                                                                    const prevStateObj = [...prevState];
                                                                    if (event.target.checked) {
                                                                        prevStateObj.push(Number(event.target.value));
                                                                    } else if (!event.target.checked && event.target.value && (prevStateObj.indexOf(Number(event.target.value) > -1))) {
                                                                        prevStateObj.splice(prevStateObj.indexOf(Number(event.target.value)), 1);
                                                                    }
                                                                    return prevStateObj;
                                                                });
                                                            } catch (err) { showBoundary(err); }
                                                        }}
                                                    />
                                                    <span className='custom-checkmark'></span>
                                                    <span> {spreadsheet.headers[colIdx]} </span>
                                                </label>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </FormControl>
                    </Grid>
                    {
                        showCategoryColumnSelection()
                            ? <Grid item xs={4}>
                                <FormControl variant="standard" fullWidth className='custom-select'>
                                    <label htmlFor="" className='custom-select-label'>Kategoriale Spalte</label>
                                    <select
                                        value={categoryCol}
                                        label="Kategoriale Spalte"
                                        id='kategoriale_spalte'
                                        data-testId = 'category-cloumn-select'
                                        onChange={(event) => {
                                            try {
                                                setCategoryCol(Number(event.target.value));
                                                // Remove column selected from the 'Spalten' selection. Column cannot be used in both category and value
                                                const removeValueIndex = cols.indexOf(Number(event.target.value));
                                                if (removeValueIndex > -1) { // only splice array when item is found
                                                    setCols((prevState) => {
                                                        const prevStateObj = [...prevState];
                                                        prevStateObj.splice(removeValueIndex, 1);
                                                        return prevStateObj;
                                                    });
                                                }
                                            } catch (err) { showBoundary(err); }
                                        }}
                                    >
                                        <option key={-1} value={-1} data-testId = 'category-cloumn-options'></option>
                                        {
                                            spreadsheet.data[0].map((_, colIdx) => {
                                                return (
                                                    <option key={colIdx} value={colIdx} data-testId = 'category-cloumn-options' >{spreadsheet.headers[colIdx]}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </FormControl>
                            </Grid>
                            : <Grid item xs={4}>
                                <FormControl variant="standard" fullWidth className='custom-select'>
                                    <label htmlFor="" className='custom-select-label'>X-Achse</label>
                                    <select
                                        value={xAxes}
                                        label="x-Achse"
                                        id='x_axis'
                                        data-testId = 'x-axis-select'
                                        onChange={(event) => {
                                            try {
                                                const columnValue = Number(event.target.value);
                                                if (!cols.includes(columnValue)) {
                                                    setCols(prevCols => [...prevCols, columnValue]);
                                                }
                                                setXAxes(columnValue);
                                            } catch (err) {
                                                showBoundary(err);
                                            }
                                        }}
                                        required
                                    >
                                        {spreadsheet.data[0].map((_, colIdx) => (
                                            <option key={colIdx} value={colIdx} data-testId = 'x-axis-options'>{spreadsheet.headers[colIdx]}</option>
                                        ))}
                                    </select>
                                </FormControl>
                            </Grid>

                    }
                </Grid>
                <br />
            </div>
            )}
            { activeTab === 1 && (<div>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="body2" color='text.secondary'>
                                Achsenbeschriftung wählen
                    </Typography>
                </Grid>
                <Grid container spacing={2} columnSpacing={0}>
                    <Grid item xs={6}>
                        <TextField
                            variant="standard"
                            label='x-Achse'
                            value={xLabel}
                            onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } }}
                            onChange={(event) => { try { setXLabel(event.target.value); } catch (err) { showBoundary(err); } }} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="standard"
                            label='y-Achse'
                            onChange={(event) => { try { setYLabel(event.target.value); } catch (err) { showBoundary(err); } }}
                            onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } }}
                            value={yLabel} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="body2" color='text.secondary'>
                                Skalierung der Achsen
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} columnSpacing={0}>
                    {
                        isLineOrScatterplotChart()
                            ? <><Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    label='minX'
                                    type='number'
                                    inputMode="decimal"
                                    lang="en_001"
                                    value={xMin}
                                    // error={errors.xMinError}
                                    // helperText={errors.xMinError ? `xMin darf nicht größer als ${computedValues.xMin} sein` : ''}
                                    onChange={(event) => { try { event.target.value ? setXMin(parseFloat(event.target.value)) : setXMin(undefined); } catch (err) { showBoundary(err); } } }
                                    onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } } } />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    variant="standard"
                                    label='maxX'
                                    type='number'
                                    inputMode="decimal"
                                    lang="en_001"
                                    value={xMax}
                                    // error={errors.xMaxError}
                                    // helperText={errors.xMaxError ? `xMax darf nicht kleiner als ${computedValues.xMax} sein` : ''}
                                    onChange={(event) => { try { event.target.value ? setXMax(parseFloat(event.target.value)) : setXMax(undefined); } catch (err) { showBoundary(err); } } }
                                    onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } } } />
                            </Grid></>
                            : <></>
                    }
                    <Grid item xs={isLineOrScatterplotChart() ? 3 : 6}>
                        <TextField
                            variant="standard"
                            label='minY'
                            type='number'
                            inputMode="decimal"
                            lang="en_001"
                            // defaultValue={getYScaleVals().yMin}
                            value={yMin}
                            // error={errors.yMinError}
                            // helperText={errors.yMinError ? `yMin darf nicht größer als ${computedValues.yMin} sein` : ''}
                            onChange={(event) => { try { event.target.value ? setYMin(parseFloat(event.target.value)) : setYMin(undefined); } catch (err) { showBoundary(err); } }}
                            onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } }} />
                    </Grid>
                    <Grid item xs={isLineOrScatterplotChart() ? 3 : 6}>
                        <TextField
                            variant="standard"
                            label='maxY'
                            type='number'
                            inputMode="decimal"
                            lang="en_001"
                            // defaultValue={getYScaleVals().yMax}
                            value={yMax}
                            // error={errors.yMaxError}
                            // helperText={errors.yMaxError ? `yMax darf nicht kleiner als ${computedValues.yMax} sein` : ''}
                            onChange={(event) => { try { event.target.value ? setYMax(parseFloat(event.target.value)) : setYMax(undefined); } catch (err) { showBoundary(err); } }}
                            onDoubleClick={(e) => { try { handleDoubleClick(e); } catch (err) { showBoundary(err); } }} />
                    </Grid>
                </Grid>
                <br />
            </div>)}
            { activeTab === 2 && (<div>
                <Grid item xs={12}>
                    {
                        isLineOrScatterplotChart()
                            ? <Typography gutterBottom variant="body2" color='text.secondary'>
                                        Gitter Verwaltung
                            </Typography>
                            : <></>
                    }
                </Grid>
                {
                    isLineOrScatterplotChart()
                        ? <Grid container spacing={2} columnSpacing={0}>
                            <Grid item xs={6}>
                                <Typography gutterBottom variant="body2" color='text.secondary'>
                                        X-Achse Gitter anzeigen
                                </Typography>
                                <Switch size="small" inputProps={{ 'aria-label': 'controlled' }} onChange={() => setShowXGridLines(!showXGridLines)} checked={showXGridLines} data-testId='x-grid-switch'/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography gutterBottom variant="body2" color='text.secondary'>
                                        Y-Achse Gitter anzeigen
                                </Typography>
                                <Switch size="small" inputProps={{ 'aria-label': 'controlled' }} onChange={() => setShowYGridLines(!showYGridLines)} checked={showYGridLines} data-testId='y-grid-switch'/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </Grid>
                        : <></>
                }

                <Grid item xs={12}>
                    <Typography gutterBottom variant="body2" color='text.secondary'>
                        Legendenposition wählen
                    </Typography>
                    <FormGroup style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row' }}>
                        {Object.keys(legendPositionLabels).map((label) => (
                            <FormControlLabel
                                key={legendPositionLabels[label]}
                                control={
                                    <Checkbox
                                        checked={legendPosition === legendPositionLabels[label]}
                                        onChange={() => setLegendPosition(legendPositionLabels[label])}
                                    />
                                }
                                label={label}
                                style={{ margin: '0.5rem' }} // Adjust margin as needed
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </div>)}
            <Divider />
            <Button
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ArrowForwardIcon />}
                style={FORWARD_BTN_STYLE}
                onClick={(e) => {
                    e.preventDefault(); // Verhindert das Standardverhalten, wie z.B. das Verlassen der Seite
                    const hasErrors = validateAxisInput(); // Validierung überprüfen

                    if (!hasErrors) { // Nur speichern, wenn keine Fehler vorliegen
                        try {
                            saveDiagramSettings(e);
                            contextManager.setBoardZoomStatus(true);
                            setIsSettingsOpen(false);
                        } catch (err) {
                            showBoundary(err);
                        }
                    }
                }}
                disabled={isSaveDisabled()}
            >
                Fertig
            </Button>

            <Button
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ArrowForwardIcon />}
                style={FORWARD_BTN_STYLE}
                onClick={(e) => {
                    e.preventDefault();
                    const hasErrors = validateAxisInput();

                    if (!hasErrors) {
                        try {
                            saveDiagramSettings(e);
                            setShowLinesSettings(true);
                        } catch (err) {
                            showBoundary(err);
                        }
                    }
                }}
            >
                {type === DIAGRAM_TYPES.LINECHART ? 'Linien bearbeiten' : type === DIAGRAM_TYPES.SCATTERPLOTCHART ? 'Punkte bearbeiten' : 'Balken bearbeiten'}
            </Button>

            <Button
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ArrowForwardIcon />}
                style={FORWARD_BTN_STYLE}
                onClick={(e) => {
                    e.preventDefault();
                    const hasErrors = validateAxisInput();

                    if (!hasErrors) {
                        try {
                            saveDiagramSettings(e);
                            setShowColorSchemeSettings(true);
                        } catch (err) {
                            showBoundary(err);
                        }
                    }
                }}
            >
                Farb-Schema anpassen
            </Button>
        </div>
    );
};
