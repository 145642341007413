import { addConnection, removeConnection } from '../../model/features/boards/boardsSlice';

export function getAnchorArrow (dispatch, boardId, setCurrentAnchor, setShowMousePointer, resetAnchor) {
    return (currentAnchor, componentId) => {
        if (currentAnchor) {
            if (currentAnchor !== componentId) { // Prevent self connections
                dispatch(addConnection({ id: boardId, fromComponentId: currentAnchor, toComponentId: componentId }));
            }

            resetAnchor(setCurrentAnchor, setShowMousePointer);
        } else {
            setShowMousePointer(true);
            setCurrentAnchor(componentId);
        }
    };
}

export function getResetAnchor (setCurrentAnchor, setShowMousePointer) {
    return () => {
        setCurrentAnchor();
        setShowMousePointer(false);
    };
}

export function getRemoveArrow (dispatch, boardId) {
    return (from, to) => {
        dispatch(removeConnection({ id: boardId, fromComponentId: from, toComponentId: to }));
    };
}
