export const SETTINGS_CONTAINER_STYLE = {
    padding: '10px',
    height: '100%',
    width: '900px',
    overflowY: 'scroll'
};

export const SELECTION_STYLE = {
    margin: '2px auto'
};

export const FORWARD_BTN_STYLE = {
    margin: '10px',
    float: 'right'
};

export const DIAGRAM_CONTAINER_STYLE = {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
};

export const MENUPROPS_STYLE = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center'
    }
    // getContentAnchorEl: null
};

export const SETTINGS_BTN_STYLE = {
    position: 'fixed',
    opacity: '0.5'
};

export const DIAGRAM_SETTINGS_MODAL_CONTAINER_STYLE = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export const DIAGRAM_ICON_SETTINGS = {
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
