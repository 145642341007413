import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectDiagram,
    selectDiagramError,
    setDiagramSettings as persistDiagramSettings
} from '../../../../model/features/diagrams/diagramsSlice';
import { selectSpreadsheet } from '../../../../model/features/spreadsheets/spreadsheetsSlice';
import DiagramSettings from './Settings/DiagramSettings';
import DiagramVisual from './DiagramVisual';
import { COMPONENT_TYPES } from '../../../../model/features/functions/loaders';
import { USER_ROLES } from '../../../users/users';
import { BOARD_TYPES } from '../../../Board/boardConfig';
import { useErrorBoundary } from 'react-error-boundary';
import { DIAGRAM_TYPES } from './DiagramTypes/diagramTypes';
import { updateSize } from '../../../../model/features/boards/boardsSlice';
import { processSpreadsheetData } from './diagramSpreadsheetUtils';

export default function Diagram ({
    docName,
    boardId,
    containerId,
    contextManager,
    boardType
}) {
    // Vordefinierte Farboptionen
    let predefinedColorsBaseline = [
        '#ff0000',
        '#0000ff',
        '#00ff00',
        '#ffff00',
        '#00ffff',
        '#808080',
        '#ffa500',
        '#800080',
        '#000000',
        '#ffffff',
        '#ffc0cb',
        '#00008b'
    ];

    const dispatch = useDispatch();
    const { showBoundary } = useErrorBoundary();
    const [showDiagramVisual, setShowDiagramVisual] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const studentOnBoardSktipt = contextManager.userRole === USER_ROLES.STUDENT && boardType === BOARD_TYPES.CLASS;
    const diagramInfo = useSelector(state => selectDiagram(state, docName));
    const spreadsheet = useSelector(state => selectSpreadsheet(state, diagramInfo.ssid));
    const err = useSelector(state => selectDiagramError(state, docName));

    const diagramProperties = JSON.parse(diagramInfo.settings);
    const [currentType, setCurrentType] = useState(diagramInfo.type);

    useEffect(() => {
        contextManager.loadComponent(boardId, docName, COMPONENT_TYPES.DIAGRAM);
    }, [boardId, docName, contextManager]);

    const hasNonEmptyValue = spreadsheet.data.some(row =>
        row.some(cell => cell.value !== '')
    );

    useEffect(() => {
        if (diagramInfo.type && diagramInfo.ssid && diagramInfo.settings) {
            try {
                const parsedSettings = JSON.parse(diagramInfo.settings);
                if (Object.keys(parsedSettings).length > 0) {
                    setShowDiagramVisual(true);
                    setCurrentType(diagramInfo.type);
                }
            } catch (error) {
                console.error('Error parsing diagram settings:', error);
            }
        }
    }, [diagramInfo]);

    useEffect(() => {
        dispatch(updateSize(boardId, containerId, { width: 450, height: 450 }));
    }, []);

    useEffect(() => {
        useEffectFuntion({ useEffectType: 'spreadsheet' });
    }, [spreadsheet, diagramInfo.type, diagramProperties.xAxes, diagramProperties.categoryColumn]);
    if (err) {
        showBoundary(err);
        return null;
    }

    function useEffectFuntion ({ useEffectType }) {
        // This section handles the changes made to the spreadsheet
        if (showDiagramVisual && hasNonEmptyValue) {
            let currentRows = diagramProperties.rows.filter(rowIdx => spreadsheet.data[rowIdx]);
            let currentCols = diagramProperties.cols.filter(colIdx => spreadsheet.data.some(row => row[colIdx]));
            // Define a new variable for the updated color scheme
            const updatedColorScheme = [...diagramProperties.colorScheme];

            if (spreadsheet.data[0].length > diagramProperties.colorScheme.length) {
                // Add new color (black) to the color scheme for each new column
                while (updatedColorScheme.length < spreadsheet.data[0].length) {
                    updatedColorScheme.push('#000000'); // Add black color
                }
            }
            const { xMin, xMax, yMin, yMax } = processSpreadsheetData({ spreadsheet, diagramInfo, currentRows, currentCols, xAxes: diagramProperties.xAxes, currentType });
            if (currentType !== diagramInfo.type) {
                setCurrentType(diagramInfo.type);
                currentRows = spreadsheet.data.map((_, idx) => idx);
                if (diagramInfo.type === DIAGRAM_TYPES.LINECHART || diagramInfo.type === DIAGRAM_TYPES.SCATTERPLOTCHART) {
                    currentCols = spreadsheet.data[0].map((_, idx) => idx);
                } else {
                    currentCols = spreadsheet.data[0].map((_, idx) => idx).filter(idx => idx !== diagramProperties.categoryColumn);
                }
            }
            if (diagramInfo.colorOptions && diagramInfo.colorOptions.length > predefinedColorsBaseline.length) {
                predefinedColorsBaseline = diagramInfo.colorOptions;
            }
            const diagramSettings = {
                rows: currentRows,
                cols: currentCols,
                categoryColumn: diagramProperties.categoryColumn,
                xAxes: diagramProperties.xAxes,
                xLabel: diagramProperties.xLabel,
                yLabel: diagramProperties.yLabel,
                yMin: parseFloat(yMin),
                yMax: parseFloat(yMax),
                xMin: parseFloat(xMin),
                xMax: parseFloat(xMax),
                showXGridLines: diagramProperties.showXGridLines,
                showYGridLines: diagramProperties.showYGridLines,
                legendPosition: diagramProperties.legendPosition,
                lineType: diagramProperties.lineType,
                lineThickness: diagramProperties.lineThickness,
                pointType: diagramProperties.pointType,
                opacity: diagramProperties.opacity,
                pointSize: diagramProperties.pointSize,
                barWidth: diagramProperties.barWidth,
                colorScheme: updatedColorScheme,
                colorOptions: predefinedColorsBaseline
            };
            dispatch(persistDiagramSettings(docName, JSON.stringify(diagramSettings)));
        }
    }

    return (
        showDiagramVisual && hasNonEmptyValue && !isSettingsOpen
            ? <DiagramVisual docName={docName} boardId={boardId} containerId={containerId} contextManager={contextManager} diagramInfo={diagramInfo} setShowDiagramVisual={setShowDiagramVisual} setIsSettingsOpen={setIsSettingsOpen}/>
            : hasNonEmptyValue && isSettingsOpen
                ? <DiagramSettings docName={docName} boardId={boardId} containerId={containerId} contextManager={contextManager} diagramInfo={diagramInfo} setShowDiagramVisual={setShowDiagramVisual} studentOnBoardSktipt={studentOnBoardSktipt} setIsSettingsOpen={setIsSettingsOpen} spreadsheet={spreadsheet}/>
                : <div>Loading...</div>
    );
}
