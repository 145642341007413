import { createSelector, createSlice } from '@reduxjs/toolkit';

// Initial state with default values
const initialState = {
    type: '',
    ssid: ''
};

// Create the slice
const diagramConfigSlice = createSlice({
    name: 'diagramConfig',
    initialState,
    reducers: {
        setType (state, action) {
            state.type = action.payload;
        },
        setSSID (state, action) {
            state.ssid = action.payload;
        }
    }
});

// Export the actions
export const { setType, setSSID } = diagramConfigSlice.actions;

// Export the reducer
export default diagramConfigSlice.reducer;

// Selector to get the diagram config state
export const selectDiagramConfig = state => state.diagramConfig;

// Selector to get the type from diagramConfig slice
export const selectType = createSelector(
    selectDiagramConfig,
    (diagramConfig) => diagramConfig.type
);

// Selector to get the ssid from diagramConfig slice
export const selectSSID = createSelector(
    selectDiagramConfig,
    (diagramConfig) => diagramConfig.ssid
);
