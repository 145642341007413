import React, { useState, useEffect } from 'react';
import {
    FORWARD_BTN_STYLE
} from '../styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {
    Button,
    TextField,
    Grid,
    Divider,
    Typography,
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
    setDiagramSettings as persistDiagramSettings
} from '../../../../../model/features/diagrams/diagramsSlice';
import { DIAGRAM_TYPES } from '../DiagramTypes/diagramTypes.js';

export default function DiagramSettingLinesProperties ({
    docName,
    boardId,
    containerId,
    contextManager,
    diagramInfo,
    setShowLinesSettings
}) {
    const dispatch = useDispatch();

    const [lineType, setLineType] = useState('solid');
    const [pointType, setPointType] = useState('circle');
    const [lineThickness, setLineThickness] = useState(1);
    const [pointSize, setPointSize] = useState(3);
    const [opacity, setOpacity] = useState(1);
    const [barWidth, setBarWidth] = useState(1);

    // Error state for fields
    const [errors, setErrors] = useState({
        pointSize: false,
        lineThickness: false,
        opacity: false,
        barWidth: false
    });

    const diagramProperties = JSON.parse(diagramInfo.settings);

    useEffect(() => {
        if (Object.keys(diagramProperties).length > 0) {
            setLineType(diagramProperties.lineType);
            setPointType(diagramProperties.pointType);
            setLineThickness(diagramProperties.lineThickness);
            setOpacity(diagramProperties.opacity);
            setPointSize(diagramProperties.pointSize);
            setBarWidth(diagramProperties.barWidth);
        }
    }, [diagramInfo.settings]);

    const handleLineTypeChange = (type) => {
        setLineType(type);
    };

    const handlePointTypeChange = (type) => {
        setPointType(type);
    };

    const validateInput = (name, value) => {
        if (value < 1 || value > 10) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: true
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: false
            }));
        }
    };

    const isInputValid = () => {
        return !errors.pointSize && !errors.lineThickness && !errors.opacity && !errors.barWidth;
    };

    const saveDiagramSettings = () => {
        if (!isInputValid()) {
            return;
        }

        const diagramProperties = JSON.parse(diagramInfo.settings);
        const diagramSettings = {
            rows: diagramProperties.rows,
            cols: diagramProperties.cols,
            categoryColumn: diagramProperties.categoryColumn,
            xAxes: diagramProperties.xAxes,
            xLabel: diagramProperties.xLabel,
            yLabel: diagramProperties.yLabel,
            yMin: diagramProperties.yMin,
            yMax: diagramProperties.yMax,
            xMin: diagramProperties.xMin,
            xMax: diagramProperties.xMax,
            showXGridLines: diagramProperties.showXGridLines,
            showYGridLines: diagramProperties.showYGridLines,
            legendPosition: diagramProperties.legendPosition,
            lineType: lineType,
            lineThickness: lineThickness,
            pointType: pointType,
            pointSize: pointSize,
            opacity: opacity,
            barWidth: barWidth,
            colorScheme: diagramProperties.colorScheme,
            colorOptions: diagramProperties.colorOptions
        };
        dispatch(persistDiagramSettings(docName, JSON.stringify(diagramSettings)));
    };

    const isLineOrScatterplotChart = () => {
        return [DIAGRAM_TYPES.LINECHART, DIAGRAM_TYPES.SCATTERPLOTCHART].includes(diagramInfo.type);
    };

    return (
        <React.Fragment>
            <div data-testId='diagramm-settings-lines-properties'>
                {/* If diagram type is linechart, display all fields */}
                {diagramInfo.type === DIAGRAM_TYPES.LINECHART && (
                    <>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='line-type-label'>
                            Linientyp ändern
                        </Typography>
                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row' }} data-testId='line-type-group'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={lineType === 'solid'}
                                        onChange={() => handleLineTypeChange('solid')}
                                    />
                                }
                                label="Durchgezogen"
                                style={{ margin: '0.5rem' }}
                                data-testId='line-type-solid'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={lineType === 'dashed'}
                                        onChange={() => handleLineTypeChange('dashed')}
                                    />
                                }
                                label="Gestrichelt"
                                style={{ margin: '0.5rem' }}
                                data-testId='line-type-dashed'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={lineType === 'dotted'}
                                        onChange={() => handleLineTypeChange('dotted')}
                                    />
                                }
                                label="Gepunktet"
                                style={{ margin: '0.5rem' }}
                                data-testId='line-type-dotted'
                            />
                        </FormGroup>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                )}

                {/* Punktentyp ändern */}
                {isLineOrScatterplotChart() && (
                    <>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='point-type-label'>
                            Punktentyp ändern
                        </Typography>
                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row' }} data-testId='point-type-group'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pointType === 'circle'}
                                        onChange={() => handlePointTypeChange('circle')}
                                    />
                                }
                                label="Kreis"
                                style={{ margin: '0.5rem' }}
                                data-testId='point-type-circle'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pointType === 'rect'}
                                        onChange={() => handlePointTypeChange('rect')}
                                    />
                                }
                                label="Rechteck"
                                style={{ margin: '0.5rem' }}
                                data-testId='point-type-rect'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={pointType === 'triangle'}
                                        onChange={() => handlePointTypeChange('triangle')}
                                    />
                                }
                                label="Dreieck"
                                style={{ margin: '0.5rem' }}
                                data-testId='point-type-triangle'
                            />
                        </FormGroup>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                )}

                {/* Punktgröße ändern */}
                {isLineOrScatterplotChart() && (
                    <>
                        {/* Hinweis für den Benutzer */}
                        <Typography variant="caption" color="textSecondary">
                            Alle numerischen Felder akzeptieren nur Werte zwischen 1 und 10.
                        </Typography>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='point-size-label'>
                            Punktgröße ändern
                        </Typography>
                        <TextField
                            type="number"
                            value={pointSize}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setPointSize(value);
                                validateInput('pointSize', value);
                            }}
                            inputProps={{ min: 1, max: 10, step: 1 }}
                            error={errors.pointSize}
                            helperText={errors.pointSize ? 'Wert muss zwischen 1 und 10 liegen.' : ''}
                            fullWidth
                            data-testId='point-size-input'
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                )}

                {/* Liniendicke ändern - Only for linechart */}
                {diagramInfo.type === DIAGRAM_TYPES.LINECHART && (
                    <>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='line-thickness-label'>
                            Liniendicke ändern
                        </Typography>
                        <TextField
                            type="number"
                            value={lineThickness}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setLineThickness(value);
                                validateInput('lineThickness', value);
                            }}
                            inputProps={{ min: 1, max: 10, step: 1 }}
                            error={errors.lineThickness}
                            helperText={errors.lineThickness ? 'Wert muss zwischen 1 und 10 liegen.' : ''}
                            fullWidth
                            data-testId='line-thickness-input'
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </>
                )}

                {/* Durchsichtigkeit ändern */}
                {isLineOrScatterplotChart() && (
                    <>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='opacity-label'>
                            Durchsichtigkeit ändern
                        </Typography>
                        <TextField
                            type="number"
                            value={opacity}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setOpacity(value);
                                validateInput('opacity', value);
                            }}
                            inputProps={{ min: 1, max: 10, step: 1 }}
                            error={errors.opacity}
                            helperText={errors.opacity ? 'Wert muss zwischen 1 und 10 liegen.' : ''}
                            fullWidth
                            data-testId='opacity-input'
                        />
                    </>
                )}

                {/* Bar Width and Opacity Fields */}
                {!isLineOrScatterplotChart() && (
                    <>
                        {/* Hinweis für den Benutzer */}
                        <Typography variant="caption" color="textSecondary">
                            Alle numerischen Felder akzeptieren nur Werte zwischen 1 und 10.
                        </Typography>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='bar-width-label'>
                            Balkenbreite ändern
                        </Typography>
                        <TextField
                            type="number"
                            value={barWidth}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setBarWidth(value);
                                validateInput('barWidth', value);
                            }}
                            inputProps={{ min: 1, max: 10, step: 1 }}
                            error={errors.barWidth}
                            helperText={errors.barWidth ? 'Wert muss zwischen 1 und 10 liegen.' : ''}
                            fullWidth
                            data-testId='bar-width-input'
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Typography gutterBottom variant="body2" color='text.secondary' data-testId='bar-opacity-label'>
                            Durchsichtigkeit ändern
                        </Typography>
                        <TextField
                            type="number"
                            value={opacity}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                setOpacity(value);
                                validateInput('opacity', value);
                            }}
                            inputProps={{ min: 1, max: 10, step: 1 }}
                            error={errors.opacity}
                            helperText={errors.opacity ? 'Wert muss zwischen 1 und 10 liegen.' : ''}
                            fullWidth
                            data-testId='bar-opacity-input'
                        />
                    </>
                )}
            </div>
            <Button
                variant="contained"
                color='primary'
                size='small'
                endIcon={<ArrowForwardIcon />}
                style={FORWARD_BTN_STYLE}
                onClick={(e) => {
                    if (isInputValid()) {
                        setShowLinesSettings(false);
                        saveDiagramSettings();
                    }
                }}
                data-testId='save-button'
            >
                Speichern und Zurück
            </Button>
        </React.Fragment>
    );
}
