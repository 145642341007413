import React, { useState, useEffect } from 'react';
import {
    Button,
    Typography,
    FormGroup,
    Grid,
    Divider
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { setDiagramSettings as persistDiagramSettings } from '../../../../../model/features/diagrams/diagramsSlice';
import { selectSpreadsheet } from '../../../../../model/features/spreadsheets/spreadsheetsSlice';

// Vordefinierte Farboptionen
const predefinedColorsBaseline = [
    '#ff0000',
    '#0000ff',
    '#00ff00',
    '#ffff00',
    '#00ffff',
    '#808080',
    '#ffa500',
    '#800080',
    '#000000',
    '#ffffff',
    '#ffc0cb',
    '#00008b'
];

export default function ColorSchemeSelector ({ docName, contextManager, diagramInfo, setShowColorSchemeSettings }) {
    const dispatch = useDispatch();
    const [predefinedColors, setPredefinedColors] = useState(predefinedColorsBaseline);
    // Farbschema aus Diagrammeinstellungen oder Standardfarben
    const [colorScheme, setColorScheme] = useState([]);
    const [activeColorIndex, setActiveColorIndex] = useState(null); // Verfolgt, welcher Farbbutton angeklickt wurde
    const [showColorPicker, setShowColorPicker] = useState(false); // Steuert, ob die Farbauswahl angezeigt wird
    const [showAddingColorDialog, setShowAddingColorDialog] = useState(false); // Steuert, ob das Dialogfeld zum Hinzufügen einer benutzerdefinierten Farbe angezeigt wird
    const [customColor, setCustomColor] = useState(''); // Benutzerdefinierte Farbe

    const spreadsheet = useSelector((state) => selectSpreadsheet(state, diagramInfo.ssid));

    const diagramProperties = diagramInfo.settings ? JSON.parse(diagramInfo.settings) : {};

    useEffect(() => {
        // Setzt das Farbschema aus den Diagrammeigenschaften oder den Standardfarben
        if (diagramProperties.colorScheme && diagramProperties.colorScheme.length > 0) {
            setColorScheme(diagramProperties.colorScheme);
        }
        if (diagramProperties.colorOptions && !showAddingColorDialog) {
            setPredefinedColors(diagramProperties.colorOptions);
        }
    }, [diagramInfo.settings, spreadsheet]);

    const handleColorChange = (color) => {
        const updatedColors = [...colorScheme];
        updatedColors[activeColorIndex] = color;
        setColorScheme(updatedColors);
        setShowColorPicker(false); // Zurück zur Hauptansicht
    };

    const saveColorScheme = () => {
        const updatedSettings = {
            ...diagramProperties,
            colorScheme: colorScheme
        };
        dispatch(persistDiagramSettings(docName, JSON.stringify(updatedSettings)));
    };

    const saveColorOptions = () => {
        const updatedSettings = {
            ...diagramProperties,
            colorOptions: predefinedColors
        };
        dispatch(persistDiagramSettings(docName, JSON.stringify(updatedSettings)));
    };

    const toggleColorPicker = (index) => {
        setActiveColorIndex(index); // Setzt den aktiven Farbindex
        setShowColorPicker(true); // Zeigt den Farbwähler an
    };

    const closeShowAddingColorDialog = () => {
        setCustomColor('');
        setShowAddingColorDialog(false);
    };

    const addColor = () => {
        if (customColor) {
            const updatedColors = [...predefinedColors, customColor];
            setPredefinedColors(updatedColors);
            setCustomColor('');
        }
    };

    return (
        <div style={{ padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-testId='diagramm-settings-color-properties'>
            <div style={{ width: '100%', maxWidth: '600px' }}>
                {
                    !showColorPicker
                        ? (
                            <>
                                <Typography variant="h6" gutterBottom style={{ padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    Farb-Schema anpassen
                                </Typography>
                                {/* Scrollbarer Container */}
                                <div style={{ maxHeight: '300px', overflowY: 'auto', paddingRight: '1rem' }}>
                                    <FormGroup>
                                        {spreadsheet?.headers.map((header, index) => (
                                            <Grid container key={index} alignItems="center" spacing={2}>
                                                <Grid item xs={3}>
                                                    <Typography variant="body1" style={{ paddingTop: '10px' }}>
                                                        {header}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            height: '40px',
                                                            backgroundColor: colorScheme[index] || '#000000',
                                                            borderRadius: '4px',
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                        title={colorScheme[index] || '#000000'}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} style={{ textAlign: 'center' }}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => toggleColorPicker(index)}
                                                        style={{
                                                            padding: '4px 8px', // Reduced padding for a smaller button
                                                            fontSize: '0.75rem' // Smaller font size
                                                        }}
                                                    >
                                                        Farbe Ändern
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </FormGroup>
                                </div>
                                <Grid item xs={12} style={{ margin: '1rem 0' }}>
                                    <Divider />
                                </Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => {
                                        saveColorScheme();
                                        setShowColorSchemeSettings(false);
                                    }}
                                    style={{
                                        padding: '4px 8px', // Reduced padding for the button
                                        fontSize: '0.75rem' // Smaller font size
                                    }}
                                >
                                    Speichern und Zurück
                                </Button>
                            </>
                        )
                        : (
                            <>
                                <div style={{ padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant="h6" gutterBottom>
                                    Farbe auswählen
                                    </Typography>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                                    {predefinedColors.map((color, colorIndex) => (
                                        <div
                                            key={colorIndex}
                                            onClick={() => handleColorChange(color)}
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                                backgroundColor: color,
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                border: colorScheme[activeColorIndex] === color ? '3px solid black' : '1px solid grey'
                                            }}
                                        />
                                    ))}
                                </div>
                                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setShowColorPicker(false)}
                                        style={{ margin: '1rem', padding: '4px 8px', fontSize: '0.75rem', justifyContent: 'center', alignItems: 'center' }} // Smaller button style
                                    >
                                    Zurück
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setShowAddingColorDialog(true);
                                        }
                                        }
                                        style={{ margin: '1rem', padding: '4px 8px', fontSize: '0.75rem' }} // Smaller button style
                                    >
                                    Farbe Hinzufügen (+)
                                    </Button>
                                </div>
                                {showAddingColorDialog && (
                                    <div>
                                        <div style={{ padding: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography variant="h6" gutterBottom>
                                            Benutzerdefinierte Farbe hinzufügen
                                            </Typography>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <input type="color" value={customColor} onChange={(e) => setCustomColor(e.target.value)} />
                                            </div>
                                            <div style={{ justifyContent: 'start', alignItems: 'end', display: 'flex' }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {
                                                        addColor();
                                                        saveColorOptions();
                                                    }
                                                    }
                                                    style={{ marginTop: '1rem', padding: '4px 8px', fontSize: '0.75rem' }} // Smaller button style
                                                >
                                                    Farbe Hinzufügen
                                                </Button>
                                            </div>
                                            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => closeShowAddingColorDialog()}
                                                    style={{ margin: '1rem', padding: '4px 8px', fontSize: '0.75rem' }} // Smaller button style
                                                >
                                                    Zurück
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                }
            </div>
        </div>
    );
}
